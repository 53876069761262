import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Homepage } from "./pages/Homepage";
import { Products } from "./pages/Products";
import { ProductPage } from "./pages/ProductPage";
import { Provider } from "react-redux";
import { store } from "./store/store"
import React from 'react';
import { CollectionPage } from './pages/Collection';
import { Page } from './pages/Page';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

// const [basket, setBasket]: [BasketItem[], Dispatch<SetStateAction<any>>] = useState([]);
//
// const addToBasket = (id: string, donation: number, quantity: number) => {
//     const inBasket = basket.filter(item => item.productID === id && donation === item.donation).length > 0;
//     if(inBasket) {
//         setBasket(basket.map(item => {
//             if(item.productID === id && donation === item.donation) {
//                 return new BasketItem(item.productID, item.donation, item.quantity+quantity);
//             }
//
//         }));
//     } else {
//         setBasket([new BasketItem(id, donation, quantity), ...basket]);
//     }
// }

root.render(
    <Provider store={store}>
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App />}>
                        <Route index element={<Homepage />} />
                        <Route path="products" element={<Products />} />
                        <Route path="products/:id" element={<ProductPage />} />
                        <Route path="collection/:collectionName" element={<CollectionPage />} />
                        {/* Not Found */}
                        <Route path="*" element={<Page />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
