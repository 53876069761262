import "./ProductGrid.scss"
import { Product } from "../../data_classes/Product";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectProducts, setProducts } from "../../store/productsArchiveSlice";
import { Loading } from "../Loading/Loading";
import {ImageWrapper} from "../Image/Image";

export const ProductGrid = () => {
    const productsList = useAppSelector(selectProducts);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (productsList !== null && productsList.length !== 0) {
            return;
        }

        fetch('/product_data.json').then(resp => resp.json()).then(({ products }: { products: [Product] }) => {
            dispatch(setProducts(products));
        });
    }, [productsList, dispatch]);


    if (productsList.length === 0) {
        return <Loading />
    }

    return (
        <div className={'products-grid'}>
            {productsList?.map((val: Product, idx: number) => <ProductThumbnail product={val} key={idx} />)}
        </div>
    )
}

export const ProductThumbnail = ({ product }: { product: Product }) => {
    return (
        <Link to={`/products/${product.id}`} className={'product-thumbnail'}>
            <div className={'image'}>
                <ImageWrapper fullHeight={true}>
                    <img src={product.thumbnail} alt={product.shortDescription} height="300" loading="lazy" />
                </ImageWrapper>
            </div>
            <div className={'meta'}>
                <h3 className={'title'}>{product.title}</h3>
                <div className={'price'}>£{product.price / 100}</div>
            </div>
        </Link>
    )
}