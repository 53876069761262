import React from 'react';
import { Header } from "./components/header/Header";
import { Menu } from "./components/menu/Menu";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "./store/hooks";
import { selectMenuExpanded, selectMenuAnimating } from "./store/menuSlice";
import { Footer } from './components/Footer/Footer';

function App() {
    const menuExpanded = useAppSelector(selectMenuExpanded),
        isMenuAnimating = useAppSelector(selectMenuAnimating)
    return (
        <div className="App">
            <Header />
            {
                (menuExpanded || (!menuExpanded && isMenuAnimating)) &&
                <Menu />
            }
            <Outlet />
            <Footer />
        </div>
    );
}

export default App;
