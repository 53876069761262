import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "./store";

interface MenuState {
    expanded: boolean;
    animating: boolean;
}

const initialState: MenuState = {
    expanded: false,
    animating: false,
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState: initialState,
    reducers: {
        expand: (state) => {
            state.animating = true;
            state.expanded = true;
        },
        collapse: (state) => {
            state.animating = true;
            state.expanded = false;
        },
        endMenuAnimation: (state) => {
            state.animating = false;
        },
    },
});

export const menuReducer = menuSlice.reducer
export const { expand, collapse, endMenuAnimation } = menuSlice.actions
export const selectMenuExpanded = (state: RootState) => state.menu.expanded
export const selectMenuAnimating = (state: RootState) => state.menu.animating

