import './Header.scss';
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faComments } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { expand, selectMenuExpanded } from "../../store/menuSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export const Header = () => {
    const dispatch = useAppDispatch();
    const menuExpanded = useAppSelector(selectMenuExpanded);

    return (
        <header>
            <div className={'navbar-btn-container'}>
                <button aria-expanded={menuExpanded} aria-controls={'menu'} onClick={() => dispatch(expand())} className={'menu-toggle'}>
                    <FontAwesomeIcon icon={faBars} aria-hidden={true} /> Menu
                </button>
            </div>
            <Link to={`/`} className={'logo'}>
                <h1 className={`sr-only`}>Shop CSS</h1>
                <img src={`/logo.svg`} alt="" />
            </Link>
            <nav>
                <ul>
                    <li><a href={'https://cssbristol.co.uk'}>CSS Homepage</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href={'https://forms.office.com/Pages/ResponsePage.aspx?id=MH_ksn3NTkql2rGM8aQVG4rSScpER65GiLSsV0NhBLlURE01SVY3MzlDNEVVVlNDS1BKOFJNN1dBNi4u'}><FontAwesomeIcon icon={faComments} />Suggest Merchandise</a></li>
                </ul>
            </nav>
        </header>
    )
}

Header.propTypes = {
    setMenuExpanded: PropTypes.func,
}