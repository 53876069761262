import "./Products.scss"
import {ProductGrid} from "../components/products/ProductGrid";
import ScrollToTop from "../components/ScrollToTop";

export const Products = () =>
    <ScrollToTop>
        <section className={`products-page`}>
            <h1>All Products</h1>
            <ProductGrid/>
        </section>
    </ScrollToTop>
