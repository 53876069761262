// State for products archive of last view

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {Product} from "../data_classes/Product";

interface ProductsArchiveSlice {
    products: Product[]
}

const initialState: ProductsArchiveSlice = {
    products: [],
}

export const productsArchiveSlice = createSlice({
    name: 'product-archive',
    initialState: initialState,
    reducers: {
        setProducts: (state, products: PayloadAction<Product[]>) => {
            state.products = products.payload;
        },
    },
});

export const productsArchiveReducer = productsArchiveSlice.reducer
export const { setProducts } = productsArchiveSlice.actions
export const selectProducts = (state: RootState) => state.productsArchive.products;

