import "./Image.scss";
import {ReactNode, useState, useEffect, useRef} from "react";
import {Loading} from "../Loading/Loading";

export const ImageWrapper = ({children, fullHeight = false}: {children: ReactNode, fullHeight?: boolean}) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const wrapper = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(loaded || wrapper.current === null) return;
        const img = wrapper.current.querySelector('img');

        if(img) {
            img.addEventListener('load', () => setLoaded(true));
        }
    });
    return (
        <div ref={wrapper} className={`image-wrapper ${loaded ? 'loaded' : 'not-loaded'} ${fullHeight && 'full-height'}`}>
            {children}
            {!loaded && <Loading />}
        </div>
    )
}