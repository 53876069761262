import { Link } from "react-router-dom";
import "./Footer.scss";

export const Footer = () => {
    const year = new Date(Date.now()).getFullYear();
    return (
        <footer id="footer">
            <div className="container">
                <div className="col">
                    <img className='su-logo' src="/bristolsu-logo.svg" alt="Supported by University of Bristol Students' Union" />
                    <p>CSS is part of the University of Bristol Student Union</p>
                    <p>Charity Number #1139656</p>
                </div>
                <nav className="col">
                    <ul>
                        <li><a href="/customer-service/">Customer Service</a></li>
                        <li><Link to="/terms-and-conditions/">Terms &amp; Conditions</Link></li>
                        <li><Link to="/shipping-information/">Shipping Info</Link></li>
                        <li><Link to="/return-policy/">Return Policy</Link></li>
                    </ul>
                </nav>
                <nav className="col">
                    <ul>
                        <li><a href="https://cssbristol.co.uk">Go to CSS Homepage</a></li>
                        <li><a href="https://cssbristol.co.uk/sponsors">Sponsor CSS</a></li>
                        <li><a href="https://cssbristol.co.uk/about/">About CSS</a></li>
                    </ul>
                </nav>
            </div>
            <div className="copyright">
                <img src="/css-logo.svg" alt="CSS Bristol Logo" />
                <p>Copyright &copy; {year} University of Bristol Computer Science Society</p>
            </div>
        </footer>
    );
}