import './Homepage.scss';
import "../components/buttons/Buttons.scss";
import { ProductGrid } from "../components/products/ProductGrid";
import ScrollToTop from "../components/ScrollToTop";
import {ImageWrapper} from "../components/Image/Image";

export const Homepage = () => {
    return (
        <ScrollToTop>
            <div className={`container home`}>
                <TuringBanner />
                <h2 id='all-products'>All Products</h2>
                <ProductGrid />
            </div>
        </ScrollToTop>

    )
}

const TuringBanner = () => {
    return (
        <div className={'turing-banner'}>
            <div className={'bg'}>
                <ImageWrapper fullHeight={true}>
                    <img src={'/pride-banner-small.jpg'} alt={''} height="500" loading="lazy" />
                </ImageWrapper>
            </div>
            <div className={'content'}>
                <div>
                    <div className={'title'}>Wear it with Pride</div>
                </div>
                <a rel='noreferrer' target="_blank" href="https://www.otrbristol.org.uk/" className={'charity'}>
                    <div className='text'>Supporting</div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 357.21 278.1">
                        <title>Bristol OTR</title>
                        <polygon points="14.6 177.72 17.72 203.61 134.32 190.21 131.19 164.32 14.6 177.72" fill="#f8dc06"></polygon>
                        <path d="M64.7,150.19c30.73,0,55.73-24.37,55.73-54.33s-25-54.33-55.73-54.33S9,65.9,9,95.86,34,150.19,64.7,150.19Zm0-81.9C80.3,68.29,93,80.66,93,95.86S80.3,123.44,64.7,123.44,36.42,111.07,36.42,95.86,49.11,68.29,64.7,68.29Z" fill="#f8dc06"></path>
                        <polygon points="253.69 36.75 255.06 5.95 201.17 3.67 201.17 3.67 170.43 2.37 170.43 2.37 114.53 0 113.16 30.8 169.06 33.17 161.89 194.16 192.64 195.47 199.8 34.47 253.69 36.75" fill="#f8dc06"></polygon>
                        <path d="M317.13,140.13a57.73,57.73,0,0,0,27-49.38c-.4-32.11-27.42-57.83-60.36-57.45l.37,30.18c16.68,0,30.29,13.09,30.5,29.39s-13.09,29.7-29.76,30.1l.33,26.64h.1l0,0,41.22,45.07,22.58-19.65Z" fill="#f8dc06"></path>
                        <rect x="238.91" y="64.51" width="30.78" height="130.96" transform="translate(-0.17 0.33) rotate(-0.07)" fill="#f8dc06"></rect>
                        <path d="M322.75,262.25c-10.8.6-16.57-6.65-20.78-11.93-3.92-4.92-5.7-6.77-8.87-6.6s-4.75,2.22-8.1,7.54c-3.6,5.72-8.47,13.55-19.32,14.15s-16.57-6.64-20.78-11.93c-3.91-4.91-5.69-6.77-8.86-6.59s-4.74,2.21-8.1,7.53c-3.59,5.72-8.47,13.55-19.31,14.15s-16.57-6.64-20.78-11.92c-3.92-4.92-5.69-6.77-8.87-6.6s-4.73,2.22-8.09,7.54c-3.59,5.71-8.48,13.55-19.32,14.15S135,265.09,130.8,259.81c-3.92-4.92-5.7-6.77-8.87-6.59s-4.73,2.21-8.1,7.53c-3.6,5.72-8.47,13.55-19.32,14.15S77.94,268.26,73.75,263c-3.93-4.91-5.71-6.77-8.88-6.59s-4.74,2.21-8.09,7.53c-3.6,5.72-8.47,13.55-19.32,14.16s-16.55-6.65-20.76-11.93c-3.92-4.92-5.7-6.77-8.87-6.6A7.11,7.11,0,0,1,6.38,245.4q.33,0,.66,0c10.73-.6,16.56,6.64,20.77,11.93,3.92,4.91,5.69,6.75,8.87,6.58s4.73-2.2,8.08-7.52c3.6-5.72,8.47-13.55,19.32-14.16s16.55,6.65,20.77,11.93c3.93,4.92,5.7,6.76,8.88,6.58s4.73-2.2,8.08-7.52c3.6-5.71,8.55-13.55,19.34-14.15s16.55,6.64,20.76,11.93c3.92,4.92,5.71,6.75,8.87,6.58s4.75-2.2,8.1-7.52c3.6-5.72,8.47-13.55,19.32-14.15s16.55,6.64,20.77,11.93c3.91,4.91,5.7,6.75,8.88,6.57s4.73-2.2,8.08-7.51c3.6-5.72,8.47-13.56,19.32-14.16s16.55,6.65,20.77,11.93c3.91,4.92,5.7,6.76,8.88,6.58s4.73-2.2,8.08-7.52c3.6-5.72,8.47-13.55,19.34-14.15s16.55,6.64,20.76,11.93c3.92,4.91,5.71,6.75,8.88,6.58s4.74-2.2,8.1-7.52c3.59-5.72,8.47-13.55,19.32-14.16a7.11,7.11,0,1,1,1.45,14.14c-.22,0-.44,0-.66,0-3.17.17-4.74,2.21-8.1,7.53C338.47,253.81,333.54,261.65,322.75,262.25Z" fill="#f8dc05"></path>
                    </svg>
                </a>
                <div className={'buy now'}>
                    <a href={'/collection/turing'} className='btn-white'>Shop the Turing Collection</a>
                </div>
            </div>
        </div >
    )
}