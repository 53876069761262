import { configureStore } from "@reduxjs/toolkit";
import { menuReducer } from "./menuSlice";
import { productsArchiveReducer } from "./productsArchiveSlice";
import { productsPageReducer } from "./productPageSlice";

export const store = configureStore({
    reducer: {
        menu: menuReducer,
        productsArchive: productsArchiveReducer,
        productPage: productsPageReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;