import { AnimationEventHandler, useEffect } from "react";
import PropTypes from "prop-types";
import "./Menu.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    collapse,
    selectMenuExpanded,
    endMenuAnimation
} from "../../store/menuSlice";
import { useAppDispatch } from "../../store/hooks";

export const Menu = () => {
    const menuExpanded = useSelector(selectMenuExpanded);
    const dispatch = useAppDispatch();

    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            if (e.code === "Escape" && menuExpanded) {
                dispatch(collapse());
            }
        });
    });

    const handleAnimationEnd: AnimationEventHandler = (e) => {
        dispatch(endMenuAnimation());
    }

    return (
        <div id='menu' className={`menu ${menuExpanded ? 'expanded' : 'collapsed'}`}
            onAnimationEnd={handleAnimationEnd}>
            <h2>Menu</h2>
            <button aria-controls={'menu'} aria-expanded={menuExpanded} onClick={() => dispatch(collapse())}>
                <FontAwesomeIcon icon={faXmark} /> Collapse
            </button>
            <nav>
                <ul>
                    <li><Link to={'/'}>Home</Link></li>
                    <li><a href={'https://cssbristol.co.uk'}>Main CSS Bristol Homepage</a></li>
                    <li><Link to={'/terms-and-conditions/'}>Terms & Conditions</Link></li>
                    <li><Link to={'/shipping-information/'}>Shipping Information</Link></li>
                    <li><Link to={'/return-policy/'}>Returns Policy</Link></li>
                    <li><Link to={'/customer-service/'}>Customer Service</Link></li>
                </ul>
            </nav>
        </div>
    )
}

Menu.propTypes = {
    setMenuExpanded: PropTypes.func,
    manuExpanded: PropTypes.bool,
}