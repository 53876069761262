import { Link, useLocation } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import './Page.scss';

export const Page = () => {
    const mappings = {
        "terms-and-conditions": Terms,
        "return-policy": Returns,
        "shipping-information": Shipping,
        "customer-service": CustomerService
    };


    const location = useLocation();
    const page = location.pathname.split('/').filter(s => s.length > 0)[0];

    if (page === undefined || !Object.keys(mappings).includes(page)) {
        console.log(location.pathname.split('/'));
        return (
            <div className='base-page'>

                <div style={{ textAlign: "center" }}><h1>Page Not Found!</h1><p
                    style={{ marginTop: "2em" }}><Link className={'btn-default'} to={'/'}>Return home</Link></p>
                </div>
            </div>);
    }
    const el = Object.entries(mappings).filter(k => k[0] === page)[0][1];

    return (
        <ScrollToTop>
            <div className='base-page'>
                {el()}
            </div>
        </ScrollToTop>
    );
}

const Terms = () =>
    <>
        <h1>Terms and Conditions</h1>
        <h2>ORDER CANCELLATIONS </h2>
        <p>For items that you choose to collect in-person, you can cancel your order at any time. For items where shipping has been selected, we can offer cancellations only if we have not already paid for shipping. In the case that we have purchased shipping but have not delivered the item, we can offer a partial refund, deducting the cost of shipping. If items have already been posted when you request a cancellation, you will need to wait until the item arrives and then start a return, for which you will be responsible for paying the return cost. </p>

        <h2>MOCK-UPS &amp; PRODUCT IMAGES</h2>

        <p>All mock-ups are shown for illustrative purposes only, and may vary slightly from the manufactured product. Where products have both real photographs as well as mock-ups, please refer to the photographs for an idea of the fitting and design of the product.</p>
        <p>Where products have only a mock-up, you acknowledge that the final product may have some slight differences and if you wish to return an item due to a small difference between the mock-up and the delivered item, you may be required to cover the cost of posting it back to us.</p>



        <h2>CHARITY FUNDRAISERS</h2>
        <p>Some items in our shop are marked as being charity fundraisers. We will total the profits from these items as orders come in and will make the donations at regular intervals, depending on the rate at which orders come in. Orders still in their return period will not be counted towards this until after the return period has ended.</p>


        <h2>COPYRIGHT</h2>
        <p>You may not use any artwork on items purchased from us for anything other than it’s intended purpose, E.g., by scanning or photographing a t-shirt design and using it for personal or commercial purposes, or by copying a design listed in our shop. </p>
    </>

const Returns = () =>
    <>
        <h1>Returns Policy</h1>
        <p>Items can be returned at full refund within the first 14 days of receiving the item. For products collected in-person this will be collection date, and shipped products, this will be counted from the date of delivery. </p>

        <p>Products must be unworn and in saleable condition for us to accept returns, with any labels or stickers originally present on the item still present. </p>

        <p>Buyers are required to handle postage costs associated with returns, unless the item was delivered damaged, or the wrong item was delivered. Items that were collected in-person can be dropped off at the Student Union in the Richmond Building at no cost. </p>

        <p><strong>You will be required to contact us to request a return.  We are unable to issue you a refund without prior approval.</strong></p>

        <p>Refunds will be issued within ten days of us receiving your item. </p>

        <p>Postage fees cannot be refunded. </p>
    </>

const Shipping = () =>
    <>
        <h1>Shipping Policy</h1>
        <h2>HOW SOON WILL I RECEIVE MY ORDER?</h2>

        <p>We aim to post most orders within three days of receiving them, but this depends on a variety of factors such as the availability of stock (see next question), as well as on the availability of committee members to pack orders (please remember that we are all student volunteers!).</p>



        <h2>WHY IS THERE SUCH A BIG RANGE FOR DELIVERY ESTIMATES?</h2>

        <p>We have items made in batches and allow back-orders once items have gone out of stock. If the item is in stock at the time of your order, it will be mailed to you within a short period of time using Hermes 48-hour delivery. If it is not in stock, we will manufacture your item as soon as possible, but manufacture times can range from one to two weeks.</p>

        <p>If you would like to check whether we have a product in stock before placing your order, feel free to get in touch with us and we may be able to reserve it for you to make sure that you receive your order quickly.</p>



        <h2>HOW DO COLLECTIONS WORK?</h2>

        <p><strong>Collection is only an option for students and staff at the University of Bristol.</strong></p>

        <p>We will have at least one slot a week in which you can collect your items from one of the University buildings and will send out details of collection times each week.</p>

        <p>If a selected date doesn’t work for you, feel free to drop us a message and we can see if any other arrangements can be made, such as collection from a reception desk or paying for postage.</p>
    </>

const CustomerService = () =>
    <>
        <h1>Customer Service</h1>
        <p>You can reach us at shop@cssbristol.co.uk with any queries.</p>
    </>