import {useLocation} from "react-router-dom";
import {ReactChild, useEffect} from "react";

const ScrollToTop =  ({children}: {children: ReactChild | ReactChild[]}) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return <>{children}</>;
}

export default ScrollToTop;