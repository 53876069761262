// State for product page of last view

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { Product } from "../data_classes/Product";

interface Status {
    type: 'error' | 'success' | 'warning' | 'none',
    content: string,
}

interface ProductPageSlice {
    product?: Product;
    status: Status;
}

const initialState: ProductPageSlice = {
    product: undefined,
    status: {
        type: 'none',
        content: '',
    }
}

export const productPageSlice = createSlice({
    name: 'product-page',
    initialState: initialState,
    reducers: {
        setProduct: (state, product: PayloadAction<Product | undefined>) => {
            state.product = product.payload;
        },
        setStatus: (state, status: PayloadAction<Status>) => {
            state.status = status.payload;
        },
    },
});

export const productsPageReducer = productPageSlice.reducer
export const { setProduct, setStatus } = productPageSlice.actions
export const selectProduct = (state: RootState) => state.productPage;