import './Collection.scss';
import "../components/buttons/Buttons.scss";
import { ProductThumbnail } from "../components/products/ProductGrid";
import ScrollToTop from "../components/ScrollToTop";
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Product } from '../data_classes/Product';
import { Loading } from '../components/Loading/Loading';

export const CollectionPage = () => {
    const [products, setProducts]: [Product[] | undefined, Dispatch<SetStateAction<Product[] | undefined>>] = useState();
    const { collectionName } = useParams();

    useEffect(() => {
        if (collectionName === undefined || products !== undefined) {
            return;
        }

        fetch('/product_data.json').then(resp => resp.json()).then(({ products }: { products: [Product] }) => {
            const productList: Product[] = products.filter(p => p.collection?.toLocaleLowerCase() === collectionName.toLowerCase());
            console.log(productList);
            setProducts(productList);
        });
    }, [collectionName, products]);


    if (products === undefined || collectionName === undefined) {
        return <Loading />
    }


    return (
        <ScrollToTop>
            <div className={`container home`}>
                <h2 id='all-products'>The {collectionName?.charAt(0).toUpperCase() + collectionName?.substring(1)} Collection</h2>
                <div className={'products-grid'}>
                    {
                        products.length > 0 ?
                            products.map(
                                (product, idx) =>
                                    <ProductThumbnail product={product} key={idx} />
                            ) : <p>No Products found in the {collectionName?.charAt(0).toUpperCase() + collectionName?.substring(1)} collection</p>
                    }
                </div>
            </div>
        </ScrollToTop>

    )
}