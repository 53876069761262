import "./ProductPage.scss"
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectProduct, setProduct, setStatus } from "../store/productPageSlice";
import ScrollToTop from "../components/ScrollToTop";
import { Product } from "../data_classes/Product";
import { Loading } from "../components/Loading/Loading";
import { ImageWrapper } from "../components/Image/Image";

export const ProductPage = () => {
    const dispatch = useAppDispatch();
    const { product, status } = useAppSelector(selectProduct);
    const { id } = useParams();

    useEffect(() => {
        if (id === undefined || product?.id === id) {
            return;
        }

        fetch('/product_data.json').then(resp => resp.json()).then(({ products }: { products: [Product] }) => {
            const selectedProduct = products[parseInt(id) - 1]

            dispatch(setProduct(selectedProduct));
            dispatch(setStatus({
                type: "none", content: ''
            }));
        });
    }, [id, dispatch, product]);


    if (product === undefined) {
        return <Loading />
    }

    return (
        <ScrollToTop>
            <main className={'product-page'}>
                <div className={'product-info'}>
                    <h1 className={'product-title'}>{product.title}</h1>
                    <p><strong>We are currently experiencing increased lead times from our suppliers, so orders may take additional time to be shipped. Please contact shop@cssbristol.co.uk if you have any concerns about this.</strong></p>

                    <div className="price">£{(product.price / 100).toFixed(2)}</div>
                    <div className={'short-description'}>
                        <div dangerouslySetInnerHTML={{ __html: product.shortDescription }} />
                    </div>

                    <Charity charityImg={product.charityImage} charityName={product.charityName} charityUrl={product.charityUrl} />

                    <div className="button-area">
                        <a href={product.purchaseUrl} rel="noopener" className="btn-default">Purchase Now</a>
                    </div>

                    <div className={'order-form'}>
                        <div className={`status ${status.type}`}>
                            {status.content}
                        </div>
                    </div>

                    <div className={'long-description'}>
                        <h3 className={'title'}>Description</h3>
                        <div dangerouslySetInnerHTML={{ __html: product.longDescription }} />
                    </div>
                    <Collaborators collaborators={product.collaborators} />
                </div>
                <div className={'gallery'}>
                    {product.images.map((src: string, idx: number) => <ImageWrapper key={idx}><img src={src} alt={''} loading="lazy" /></ImageWrapper>)}
                </div>
            </main>
            <Outlet />
        </ScrollToTop>
    )
}

const Charity = ({ charityImg, charityUrl, charityName }: { charityImg?: string, charityUrl?: string, charityName?: string }) =>
    (charityImg === undefined || charityUrl === undefined) || charityName === undefined ?
        <></> :
        <a className="charity" href={charityUrl} target="_blank" rel="noreferrer">
            <img src={charityImg} alt={`Supporting ${charityName}`} loading="lazy" />
        </a>

const Collaborators = ({ collaborators }: { collaborators?: [{ name: string, logo: string }] }) => {
    if (collaborators === undefined) {
        return <></>;
    }
    return (
        <div className="collaborators">
            <h3 className="title">Launched in collaboration with:</h3>
            {collaborators.map((society, idx) =>
                <img key={idx} src={society.logo} alt={society.name} title={society.name} height="600" loading="lazy" />
            )}
        </div>
    );
}